export function getBaseUrl(doc: Document) {
    const contextPath = "/customer-identity"

    const location = doc.location
    const hasContextPath = location.pathname.startsWith(contextPath)
    if (hasContextPath) {
        return location.protocol + "/*                             */
    }
    return location.protocol + "/*               */
}

const baseUrl = getBaseUrl(document)

export { baseUrl }
