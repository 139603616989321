import { baseUrl } from "../base_url"
import { PasswordStrengthResponse } from "./newPasswordValidator/password_strength_response"

async function fetchJSON(url: string, method: string, data: object) {
    const response = await fetch(url, {
        method: method,
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
    })
    return response.json()
}

interface ValidationResponse {
    errorMessage?: string
}

async function fetchEmailInputValidation(value: string, useStrictValidation: boolean) {
    const data = { email: value }
    if (useStrictValidation) {
        return (await fetchJSON(`${baseUrl}/rest/email/validate-strict`, RequestMethod.post, data)) as Promise<ValidationResponse>
    }
    return (await fetchJSON(`${baseUrl}/rest/email/validate`, RequestMethod.post, data)) as Promise<ValidationResponse>
}

async function fetchPasswordInputValidation(value: string) {
    const data = { password: value }
    const url = baseUrl + "/rest/password/validate"
    return (await fetchJSON(url, RequestMethod.post, data)) as Promise<ValidationResponse>
}

export interface NewPasswordValidationRequest {
    newPassword: string
    newPasswordRepeat: string
}

export interface NewPasswordValidationResponse {
    newPasswordMessage?: string
    newPasswordRepeatMessage?: string
}

async function fetchNewPasswordInputValidation(request: NewPasswordValidationRequest): Promise<NewPasswordValidationResponse> {
    const url = baseUrl + "/rest/new-password/validate"
    return (await fetchJSON(url, RequestMethod.post, request)) as Promise<NewPasswordValidationResponse>
}

async function fetchPostalCodeInputValidation(value: string) {
    const data = { postalcode: value }
    const url = baseUrl + "/rest/postalcode/validate"
    return (await fetchJSON(url, RequestMethod.post, data)) as Promise<ValidationResponse>
}

async function fetchAccountNumberInputValidation(value: string) {
    const data = { accountNumber: value }
    const url = baseUrl + "/rest/account-number/validate"
    return (await fetchJSON(url, RequestMethod.post, data)) as Promise<ValidationResponse>
}

async function fetchBirthdateInputValidation(year: string, month: string, day: string) {
    const data = { year: year, month: month, day: day }
    const url = baseUrl + "/rest/birthdate/validate"
    return (await fetchJSON(url, RequestMethod.post, data)) as Promise<ValidationResponse>
}

async function fetchPasswordStrengthInputValidation(value: string, blocklistValues: Record<string, never>) {
    const data = { password: value, blockList: blocklistValues }
    const url = baseUrl + "/rest/password-strength/validate"
    return (await fetchJSON(url, RequestMethod.post, data)) as Promise<PasswordStrengthResponse>
}

export {
    fetchEmailInputValidation,
    fetchPasswordInputValidation,
    fetchPostalCodeInputValidation,
    fetchAccountNumberInputValidation,
    fetchBirthdateInputValidation,
    fetchPasswordStrengthInputValidation,
    fetchNewPasswordInputValidation,
    ValidationResponse,
}

enum RequestMethod {
    post = "POST",
    get = "GET",
    delete = "DELETE",
    put = "PUT",
}
